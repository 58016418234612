<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>INTP</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Anda yang memiliki kepribadian INTP biasa disebut sebagai The Thinkers atau seorang pemikir. Diperkirakan 3-5% kepribadian INTP di dunia. Menurut dimensinya INTP yakni Introversion yaitu orang yang senang akan hal yang tenang dan tidak terlalu suka mengenal dunia luas. Intuition, yakni mereka yang sebenarnya menyukai hal abstrak dan berkaitan dengan masa depan. Thinking yaitu mereka yang berpikir objektif tanpa melihat siapapun itu, dan Perception dimana mereka membuat keputusan berdasarkan kesepakatan. 
          </p>
          <p>
            Ciri kepribadian INTP adalah:
            <ul>
              <li> Kreatif, berwawasan, briliant an juga berbakat.</li>
              <li> Menilai pengetahuan dan kompetensi di atas segalanya.</li>
              <li> Memiliki standar yang sangat tinggi kinerja yang mereka terapkan untuk diri mereka sendiri.</li>
              <li> Pencari kebenaran dan membawa prinsip yang teguh.</li>
              <li> Kehidupan utamanya ada dalam pikiran mereka, mungkin tampak telepas dan tidak terlibat dengan orang lain.</li>
            </ul>
          </p>
          <p>Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Belajarlah untuk menjadi seseorang yang memahami dan mengerti perasaan orang lain apalagi menghargainya karena bisa saja orang lain lebih tinggi dari anda.
              </li>
              <li>
                Cobalah menemukan ide dan mewujudkannya jangan hanya berpikir dan menyusun teori tanpa ada wujud dan tujuan jelasnya.
              </li>
              <li>
                Pekerjaan yang cocok adalah Programmer, penulis teknis, penyidik forensic, hakim, ilmu kehutanan dan juga ahli ilmu eksak.
              </li>
            </ul>
          </p>
          <p>
            Pasangan yang cocok untuk ENFJ adalah mereka yang INFP atau ISFP. Namun hal ini tidak bisa menghalangi anda untuk memilih pasangan yang cocok tanpa melihat MBTI dan pilihlah pasangan yang anda cintai.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/INTP.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Introversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="introversion">Introversion</h5>
              <p>cenderung pendiam dan suka menyendiri</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Intution.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="intution">Intution</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Thinking.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="thinking">Thinking</h5>
              <p>membuat keputusan berdasarkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Perceiving.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="perceiving">Perceiving</h5>
              <p>cenderung membiarkan pilihan tetap terbuka</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "INTP",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'INTP',
        slug: 'intp'
      }
    }
  }
};
</script>

<style scoped>

</style>
